import uniqid from 'uniqid'
import './WorkContainer.css'

const WorkContainer = ({ work }) => (

	<div className='work'>
		<ol className='group/list space-y-10 m-10'>
			<li
				key={uniqid()}
				className='group lg:group-hover/list:opacity-50 lg:hover:!opacity-100 transition-all duration-300 ease-in-out hover:after:bg-[#ad5aff0a]  after:content-[""] relative after:absolute after:w-full after:h-full after:top-0 after:left-0 after:transform after:rounded-lg after:transition-colors after:duration-300 after:ease-in-out after:drop-shadow-md hover:after:shadow-[inset_0_1px_0_0_rgba(148,163,184,0.1)]'
				>
				<div className='grid grid-cols-8'>
					<div className='col-span-8 sm:col-span-2 text-textSecondary text-sm mt-2 sm:mb-0'>
						{work.range}
					</div>
					<div className='ml-0 sm:ml-4 col-span-8 sm:col-span-6 mt-2'>
						<h3 className='text-base group-hover:text-primary'>
							{work.title} · {work.company}
						</h3>
						<span className='text-textSecondary text-sm'>{work.location}</span>
						<div className='mt-2'>
							<ul>
								{work.discipline.map((item) => (
									<li className='my-2' key={uniqid()}>
									<h3 className='text-base'>{item.subject}</h3>
										<ul>
											{item.jobs.map((job) => (
											<li key={uniqid()} className='text-sm text-textSecondary'>
												{'· '}
												{job}
											</li>
											))}
										</ul>
										<div className='works__stack'>
											{item.ability.map((ability) => (
												<ul>
													<li key={uniqid()} className='works__stack-item'>
														<div className='flex items-center rounded-full bg-teal-600/10 px-3 py-1 text-xs font-medium leading-5 text-teal-500'>
															{ability}
														</div>
													</li>
												</ul>
											))}
										</div>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</li>
		</ol>
	</div>
)

export default WorkContainer

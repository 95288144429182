const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: './',
  title: 'JKV.',
}

const about = {
  // all the properties are optional - can be left empty or deleted
  name: 'John Kenneth Valendez',
  role: 'Software Engineer & Project Manager',
  // role_description: 'My Job Title is Project Manager, but I wear multiple hats in the field of Web Development, Cloud/Devops and Project Management',
  description:
	// 'Formerly a civil engineer, he is currently working as a Project Manager while wearing multiple hats in the field of Web Development, Cloud/Devops, Data Analytics and Project Management. Being a multiple hats guy, I am a lifelong learner who is always eager to learn and integrate new technologies and ideas into my skillset.',
	'As a versatile professional, I’ve transitioned from civil engineering to the dynamic world of technology. Currently, I serve as a Project Manager, but my role extends far beyond that in the fields of Web Development, Cloud and DevOps, Data Analytics and Project Management. Curiosity fuels my growth. I’m always exploring new technologies and diving into online courses. Learning isn’t a phase—it’s a lifelong commitment.',
  resume: './resume.pdf',
  social: {
    linkedin: 'https://linkedin.com/in/jkvalendez',
    github: 'https://github.com/jkvalendez',
  },
}

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Project 1',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project 2',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project 3',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    stack: ['SASS', 'TypeScript', 'React'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]

const experiences = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: 'Web Development',
    company: 'System Up Business Solutions, Inc.',
    job: 'Project Manager',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    // description2:
    //   'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    tasks: ['Design and build Company Website using Bootstrap', 
            'Manage and develop the front end features for the web applications of the company', 
            'Design and develop print forms and reports for the web applications',
			'Create dashboard to show overall data of a SaaS product using Bootstrap',
			'Create dashboard that serve as menu to navigate the web application using TailwindCSS',
			'Implement Google API with SaaS Product (Cloud SQL, Secret Manager & Buckets)'
    ],
    exp: ['Test1', 'Test2', 'Test3'],
    stack: ['JavaScript', 'HTML', 'CSS', 'PHP', 'Figma', 'Git', 'SVN', 'Bootstrap', 'TailwindCSS', 'SQL', 'Wordpress'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Cloud/DevOps',
    company: 'System Up Business Solutions, Inc.',
    job: 'Project Manager',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    tasks: ['Manage the source code updates made by the developers of the company', 
            'Manage deployments both in the development and production server of web applications', 
            'Setup and manage cloud servers to be used for web application deployments', 
            'Implemented serverless setup for the dependencies of the main web application product of the company which saves time in setting up new servers for deployment', 
            'Monitor cloud servers and devise solutions to make cloud server run in optimal speed',
            'Collaborate with partner clients for source code integration in their servers', 
            'Introduced source control to be used by developers as part of the software development process',
            'Create SSL encryptions for domain names used by the web applications'
    ],
    exp: ['Test4', 'Test5', 'Test6'],
    stack: ['Docker', 'Linux', 'Bash Scripting', 'Apache', 'Google Cloud Platform', 'Jenkins'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
  {
    name: 'Project Management',
    company: 'System Up Business Solutions, Inc.',
    job: 'Project Manager',
    description:
      'Amet asperiores et impedit aliquam consectetur? Voluptates sed a nulla ipsa officia et esse aliquam',
    tasks: ['Manage projects from initiation, scoping, design, implementation, execution and closing', 
            'Standardized processes to be able to keep optimum performance for tasks', 
            'Created external knowledge base to be used by clients in navigating the products of the company', 
            'Created internal knowledge base to be used by employees to be familiarized with the processes of the company', 
            'Collaborate with the clients to ensure that project goals are properly met',
            'Guides and supports developers in communicating their tasks to accomplish their project goals smoothly',
            'Design company logo',
            'Acts as the Administrator to Manage the company\'s Google Workspace',
            'Set weekly goals for the development team to meet project milestones and deadlines',
            'Perform product demonstration for potential clients',
            'Create software product quotation, contracts and project timeline',
			'Acted as facilitator and the one who manages and guides the team of data extractors to ensure that proper process and efficiency of extraction is followed and implemented'
    ],
    stack: ['Google Workspace', 'Project Management', 'Agile', 'Negotiation'],
    sourceCode: 'https://github.com',
    livePreview: 'https://github.com',
  },
]

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  'HTML',
  'CSS',
  'JavaScript',
  'PHP',
  'React',
  'Figma',
  'SVN',
  'Material UI',
  'Git',
  'CI/CD',
  'Linux',
  'Docker',
  'Google Cloud Platform',
]

const contact = {
  // email is optional - if left empty Contact section won't show up
  email: 'jkvalendez@gmail.com',
}

const works = [
  {
    id: '1',
    title: 'Project Manager',
    company: 'System Up Business Solutions, Inc.',
    location: 'Mandaluyong, Philippines (Hybrid)',
    range: 'July 2021 - Present',
	discipline: [
		
		{
			subject: 'Web Development',
			jobs: [
			'Design and build Company Website using Bootstrap', 
            'Manage and develop the front end features for the web applications of the company', 
            'Design and develop print forms and reports for the web applications',
			'Create dashboard to show overall data of a SaaS product using Bootstrap',
			'Create dashboard that serve as menu to navigate the web application using TailwindCSS',
			'Implement Google API with SaaS Product (Cloud SQL, Secret Manager & Buckets)'
			],
			ability: [
			  'JavaScript', 'HTML', 'CSS', 'PHP', 'Figma', 'Git', 'SVN', 'Bootstrap', 'TailwindCSS', 'SQL', 'Wordpress', 'ReactJS'
			],
		},
		{
			subject: 'Cloud / DevOps',
			jobs: [
			'Manage the source code updates made by the developers of the company', 
            'Manage deployments both in the development and production server of web applications', 
            'Setup and manage cloud servers to be used for web application deployments', 
            'Implemented serverless setup for the dependencies of the main web application product of the company which saves time in setting up new servers for deployment', 
            'Monitor cloud servers and devise solutions to make cloud server run in optimal speed',
            'Collaborate with partner clients for source code integration in their servers', 
            'Introduced source control to be used by developers as part of the software development process',
            'Create SSL encryptions for domain names used by the web applications'

			],
			ability: [
			  'Docker', 'Linux', 'Bash Scripting', 'Apache', 'Google Cloud Platform', 'Jenkins'
			],
		},
		{
			subject: 'Data Analytics',
			jobs: [
			'Analyze data of various web systems to generate accurate reports and improve products',
			'Manage the day to day data extraction operation to ensure that all the data requested by the client were properly handled',
			'Acted as facilitator and the one who manages and guides the team of data extractors to ensure that proper process and efficiency of extraction is followed and implemented',
            'Responsible for setting up the data repository of data extracted', 
            'Perform web scraping of data to efficiently increase data extraction speed for a Data Extraction project of the company',
            'Created a dashboard page to shows the general summary of data computed by the payroll system',
			],
			ability: [
			  'SQL', 'Extract, Transform, Load (ETL)'
			],
		},
		{
			subject: 'Project Management',
			jobs: [
			'Manage projects from initiation, scoping, design, implementation, execution and closing', 
            'Standardized processes to be able to keep optimum performance for tasks', 
            'Created external knowledge base to be used by clients in navigating the products of the company', 
            'Created internal knowledge base to be used by employees to be familiarized with the processes of the company', 
            'Collaborate with the clients to ensure that project goals are properly met',
            'Guides and supports developers in communicating their tasks to accomplish their project goals smoothly',
            'Design company logo',
            'Acts as the Administrator to Manage the company\'s Google Workspace',
            'Set weekly goals for the development team to meet project milestones and deadlines',
            'Perform product demonstration for potential clients',
            'Create software product quotation, contracts and project timeline',
			],
			ability: [
			  'Software Development Life Cycle (SLDC)', 'Google Workspace', 'Project Management', 'Agile', 'Negotiation'
			],
		},
	],
    duties: [
      'Lead the frontend development team and mentor some junior developers.',

	],
    stacks: ['JavaScript', 'HTML', 'CSS', 'PHP', 'Figma', 'Git', 'SVN', 'Bootstrap', 'TailwindCSS', 'SQL', 'Wordpress'],
  },
  {
    id: '2',
    title: 'Business Development Engineer',
    company: 'Vista Land and Lifescapes, Inc.',
    location: 'Muntinlupa, Philippines',
    range: 'August 2018 - May 2021',
	discipline: [
		{
			subject: 'Business Development',
			jobs: [
			  'Experienced in Property Enhancement of owned and newly bought lands.',
			  'Supervise and inspect the Construction Projects',
			  'Coordinate with the Local Government regarding Business Development on site',
			  'Coordinate with DPWH regarding concerns in the project',
			  'Facilitates bidding and prepares contracts for awarded contractors',
			  'Handled Maintenance and Security Teams that ensure the cleanliness and orderliness of the site',
			  'Processes contract management, billing, collection and field operation',
			],
			ability: [
			  'Negotiation', 'Project Management', 'Leadership', 'Land Development', 'Business Development'
			],
		},
	],
    duties: [
      'Experienced in Property Enhancement of owned and newly bought lands.',
      'Supervise and inspect the Construction Projects',
      'Coordinate with the Local Government regarding Business Development on site',
      'Coordinate with DPWH regarding concerns in the project',
      'Facilitates bidding and prepares contracts for awarded contractors',
      'Handled Maintenance and Security Teams that ensure the cleanliness and orderliness of the site',
      'Processes contract management, billing, collection and field operation',
    ],
    stacks: ['Docker', 'Linux', 'Bash Scripting', 'Apache', 'Google Cloud Platform', 'Jenkins'],
  },
];

export { header, about, projects, experiences, skills, contact, works }

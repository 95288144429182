import uniqid from 'uniqid'
import { works } from '../../portfolio'
import WorkContainer from '../WorkContainer/WorkContainer'
import './Works.css'

const Works = () => {
  if (!works.length) return null

  return (
    <section id='works' className='section works'>
	<div>
      <h2 className='section__title'>Experience</h2>
		
      <div className='works__grid'>
        {works.map((work) => (
          <WorkContainer key={uniqid()} work={work} />
        ))}
      </div>
	{/*
	  <a
        href='/assets/resume.pdf'
        target='_blank'
        rel='noopener noreferrer'
        className='link-primary'
      >
        View resume
      </a>
	*/}
	</div>
    </section>  
	)
}

export default Works
